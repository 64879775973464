import React, { useState,useEffect, useRef } from "react";
import Inputs from "../../../components/forms/Inputs";
import { useAxios } from "../../../contexts/AxiosContext";
import { ProgressSpinner } from 'primereact/progressspinner';
import { MultiSelect } from 'primereact/multiselect';
import { InputHandler } from "../../../services/EventHandlers";
import { Tooltip } from "primereact/tooltip";
import IconMap from "../../../components/Icons/IconMaps";
import { Chips } from "primereact/chips";
// import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import _ from 'lodash';
import DepartmentsOptions from "../../../options/departments.json";
import { TreeSelect } from 'primereact/treeselect';
import { AgentProps } from "../../../interface/datatypes";
import { FileUpload } from 'primereact/fileupload';
import { sha1 } from 'js-sha1';


// interface SuggestionResponse {
//   suggestion: any;
// }         
// interface DataToSendInput9vsuqzxj1f {
//   keywords: string;
//   country: any;
// }

// interface BusinessProfileData {
//   company_name: string;
//   business_description: string;
//   business_targets: string;
//   website: string;
// }

const ClientsStep: React.FC<any> = ({setEntInfo, entInfo, setLoadingState,setIsGenerated, isGenerated, defaultPrompt,countryOptions,closeOnboarding, allTemplates, csvFile, setCsvFile}) => {
  const axiosService = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState<any[]>(entInfo.target_countries);
  const [values, setValues] = useState(entInfo.suggested_keywords);
  const [values2, setValues2] = useState(entInfo.suggested_pitch);
  const [blueprintTemplates, setBlueprintTemplates] = useState([]);
  const [defaultBlueprint, setDefaultBlueprint] = useState(null);
  
  
  const fileUploadUi: any = useRef(null);
  
  /*
    const SeniorityOptions = [
      { label: "Low Seniority", value: "Low" },
      { label: "Mid Seniority", value: "Mid" },
      { label: "High Seniority", value: "High" },
    ];  
  */
  
  
  const SearchScopeOptions = [
    { label: "Broad", value: "Broad" },
    { label: "Narrow", value: "Narrow" },  
  ];

  const handleSearchScopeChange = (event: any) => {

    setEntInfo((prevValues:AgentProps) => (
      {
        ...prevValues,
        search_scope: event.value
      }
    ));  
  
  };  
  
  
  const handleHasKeywordSearchChange = (event: any) => {

    setEntInfo((prevValues:AgentProps) => (
      {
        ...prevValues,
        has_keyword_search: event.value
      }
    ));  
  
  };    
  
  
  const fileResetHandler = () =>{
    fileUploadUi.current.clear();
    setCsvFile( null );
    setEntInfo((prevValues:AgentProps) => (
      {
        ...prevValues,
        has_keyword_search: "1"
      }
    ));
  };
  
  
  const readFileAsBuffer: (file: File) => Promise<Buffer> = async(file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as Buffer);
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsBinaryString(file);
    });  
  };
  
  const fileSelectHandler = () =>{
    setTimeout(async()=>{
    
      const current: any = fileUploadUi.current;
      const files: any = current.getFiles();
      let is_valid = true;

      if( files.length === 0 ){
        is_valid = false;
      }else{
        const fileBuffer: Buffer = await readFileAsBuffer(files[0]);
        const temp_u8vxkd = sha1.hex(fileBuffer);
        if( temp_u8vxkd === '9f8d34abbffe0a89c1e784821089c72a2ee10c9c' ){
          await withReactContent(Swal).fire({
            icon: "error",
            text: 'Please modify the sample file.',
            iconColor: '#f87171',
            confirmButtonColor: '#2196f3',
          });  
          is_valid = false; 
          fileUploadUi.current.clear();     
        }else{
          let is_valid_2 = true;
          const temp_rzs558 = fileBuffer.toString().split("\n")[0];
          const temp_tzri2j = temp_rzs558.split(',');
          if( temp_tzri2j.length<4 ){
            is_valid_2 = false;
          }else if(
            temp_tzri2j[0].indexOf('Account Name') === -1
            || temp_tzri2j[1].indexOf('Billing Country') === -1
            || temp_tzri2j[2].indexOf('Industry') === -1
            || temp_tzri2j[3].indexOf('Website') === -1
            
            ){
              is_valid_2 = false;
          }
          if( !is_valid_2 ){
            await withReactContent(Swal).fire({
              icon: "error",
              text: 'Invalid CSV Format.',
              iconColor: '#f87171',
              confirmButtonColor: '#2196f3',
            }); 
            is_valid = false;
            fileUploadUi.current.clear();
          }
        }
      }

      if( is_valid ){
        setCsvFile( files );
      }else{
        setCsvFile( null );
      }
      
    }, 300);
  };
  const downloadSampleFile = () =>{
    window.open('/sample-files/sample.csv', '_blank');
  };
  
  
  const ManagementLevelOptions = [
    { label: "Owner", value: "owner" },
    { label: "Founder", value: "founder" },
    { label: "C-Suite", value: "c_suite" },
    { label: "Partner", value: "partner" },
    { label: "VP", value: "vp" },
    { label: "Head", value: "head" },
    { label: "Director", value: "director" },
    { label: "Manager", value: "manager" },
    { label: "Senior", value: "senior" },
    { label: "Entry", value: "entry" },
    { label: "Intern", value: "intern" },    
  ];    
  
  
  /*  
    const handleSeniorityChange = (event: any) => {
    
      setEntInfo((prevValues:any) => (
        {
          ...prevValues,
          target_contact_person_seniority_levels: event.value
        }
      ));  
    
    };
  */
  
  const handleManagementLevelChange = (event: any) => {
  
    setEntInfo((prevValues:AgentProps) => (
      {
        ...prevValues,
        target_contact_person_management_levels: event.value
      }
    ));  
  
  };  
  
  
  const handleDepartmentChange = (event: any) => {
    setEntInfo((prevValues:AgentProps) => (
      {
        ...prevValues,
        target_contact_person_departments: event.value
      }
    )); 
  };
  
  
  const HasKeywordSearchOptions = [
    { label: "Disable Keyword Search", value: "0" },
    { label: "Enable Keyword Search", value: "1" },
  ];    
    
  
  
  const [setUrls] = useState<any>({urls:[], random_keyword:''});
  const [isLoadingUrl, setIsLoadingUrl] = useState(false);
 
  const handleButtonClickGetUrls = () => {
    let keywords: any = values;
    let negative_keywords: any = entInfo.negative_keywords;
    let search_scope: any = entInfo.search_scope;
    setIsLoadingUrl(true);
    
    if( _.isEmpty(selectedCountries) ){
      withReactContent(Swal).fire({
        title: "Empty Selected Countries",
      });    
      setIsLoadingUrl(false);
      return;
    }
    
    if( _.isEmpty( keywords ) ){
      withReactContent(Swal).fire({
        title: "Empty Keywords",
      });    
      setIsLoadingUrl(false);
      return;
    }
    
    axiosService.api.post('/api/google-search/search', {keywords: keywords, target_countries: selectedCountries, negative_keywords: negative_keywords, search_scope: search_scope })
    .then((urls2: any) => {
        setUrls( urls2.data );
        
        if( _.get(urls2.data, 'is_not_enough_credits', false) ){
          withReactContent(Swal).fire({
            icon: 'error',
            title: "Not Enough Credits",
          });    
          setIsLoadingUrl(false);        
          return;
        }
        
        const scopeHtml = _.isEmpty( entInfo.search_scope ) ? ' (Broad)' : ` (${entInfo.search_scope})`;
        
        const previewHtml = (
          <div className="text-sm text-left">
            <p><strong>Randomly Sampled Keyword{scopeHtml}:</strong> <i>{urls2.data.random_keyword}</i></p>
            <p>&nbsp;</p>
            <div className="text-left">
            {(urls2.data.urls||[]).map((url: any, k:any) => (
              <div key={k}>
                <a href={url.url} target="_blank" rel="noopener noreferrer"><strong>&bull; {url.url}</strong> <br/>{url.title}</a><p>&nbsp;</p>
              </div>
            ))}    
            { _.isEmpty(urls2.data.urls) && 
              <div className="p-error text-center">No Results Found</div>
            }                          
            </div>
          </div>      
        );
         
        withReactContent(Swal).fire({
          title: "Preview Tentative Leads",
          html: previewHtml
        });

        setIsLoadingUrl(false);
    });
  };  
  
  
  
  // const [consultAiInputedKeywords, setConsultAiInputedKeywords] = useState<any>({urls:[], random_keyword:''});
  // const [isLoadingConsultAiInputedKeywords, setIsLoadingConsultAiInputedKeywords] = useState(false);   
  
  // const handleButtonClickGetConsultAiInputedKeywords = () => {
  //   setIsLoadingConsultAiInputedKeywords(true);
  
  //   if( _.isEmpty( entInfo.target_keywords ) ){
  //     withReactContent(Swal).fire({
  //       title: "Empty Initial Keywords",
  //     });    
  //     setIsLoadingConsultAiInputedKeywords(false);
  //     return;
  //   }
    
  //   let data_to_send = {
  //     customer_profile: entInfo.target_description,
  //     language: entInfo.target_language,
  //     inputted_keywords: entInfo.target_keywords  
  //   };
    
  //   axiosService.api.post('/api/ai_suggest/improve_inputted_keywords', data_to_send)
  //   .then((data: any) => {
    
  //     let new_keywords_rb4tj = (data.data.suggestion||[]);
      
  //     if( _.isEmpty( new_keywords_rb4tj ) ){
  //       withReactContent(Swal).fire({
  //         title: "Sorry AI Result is Empty",
  //       });    
  //       setIsLoadingConsultAiInputedKeywords(false);
  //       return;      
  //     }
    
  //     const previewHtml = (
  //       <div className="text-sm text-left">
  //         <div className="text-left">
  //         {new_keywords_rb4tj.map((keyword: any) => (
  //           <div key={keyword}>
  //             &bull; {keyword}
  //           </div>
  //         ))}                              
  //         </div>
  //       </div>      
  //     );
    
  //     withReactContent(Swal).fire({
  //       title: "Suggested Keywords",
  //       html: previewHtml,
  //       confirmButtonText: 'Apply Suggested Keywords',
  //       cancelButtonText: 'Cancel',
  //       showCancelButton: true
  //     }).then( v=>{
  //       if(v.isConfirmed){
  //         setEntInfo((prevValues:AgentProps) => (
  //           {
  //             ...prevValues,
  //             target_keywords: new_keywords_rb4tj
  //           }
  //         ));
  //       } 
  //     });    
  //     setIsLoadingConsultAiInputedKeywords(false);
  //   });
    
  // };
  
  useEffect(() => {
    setEntInfo((prevValues:AgentProps) => (
      {
        ...prevValues,
        target_countries: selectedCountries
      }
    ));
    setLoadingState(false);
    // console.log('selectedCountries :: ', selectedCountries)
    
    // const current: any = fileUploadUi.current;
    // current.setFiles( csvFile )    
    
  }, [selectedCountries]);

  // const panelFooterTemplate = () => {
  //   const length = selectedCountries ? selectedCountries.length : 0;

  //   return (
  //     <div className="py-2 px-3">
  //       <b>{length}</b> item{length > 1 ? 's' : ''} selected.
  //     </div>
  //   );
  // };

  const handleGenerating = async (event:any)=>{
    setIsLoading(true);
    setIsLoading2(true);

    let dataToSend: any = {
      company_name: entInfo.company_name,
      business_description: entInfo.description,
      business_targets: entInfo.target_description, 
      website: "https://" + entInfo.website,
      offers: entInfo.offers,
      target_keywords: entInfo.target_keywords,
      target_countries: selectedCountries,
      target_language: entInfo.target_language,
      suggested_sales_profile: entInfo.suggested_sales_profile,
      phone: entInfo.phone,
      email: entInfo.email,
      miscellaneous_links: entInfo.miscellaneous_links,
      search_scope: entInfo.search_scope,
    };

    let sampleCopy = JSON.parse(JSON.stringify(dataToSend));

    Object.keys(sampleCopy).forEach(key => {
      if(sampleCopy[key] == null || sampleCopy[key] == ""){
        delete(sampleCopy[key]);
      }
    });

    if(entInfo.outreach_template != null && entInfo.outreach_template != ""){
      sampleCopy.outreach_template = entInfo.outreach_template;
    }

    let suggestionDetails = {
      details: sampleCopy,
      prompts: {
        suggested_sales_profile: defaultPrompt.suggested_sales_profile.map((k:any) => (k.value)),
        suggested_pitch: defaultPrompt.suggested_pitch.map((k:any) => (k.value)),
        suggested_keywords: defaultPrompt.suggested_keywords.map((k:any) => (k.value))
      },
      selected_keys: defaultPrompt.include_keys
    };


    axiosService.api.post('/api/gpt-prompt/dynamic/suggest/suggested_pitch', suggestionDetails)
    .then((pitchResponse:any) => {
      setValues2(pitchResponse.data.suggestion);
      setEntInfo((prevValues: AgentProps) => ({
        ...prevValues,
        suggested_pitch:pitchResponse.data.suggestion,
      }));
      suggestionDetails.details.suggested_pitch = pitchResponse.data.suggestion;
      setIsLoading2(false);

      if(entInfo.has_keyword_search!=="0"){
        axiosService.api.post('/api/gpt-prompt/dynamic/suggest/suggested_keywords', suggestionDetails)
        .then((keywordsResponse:any) => {
          const newKeywords = keywordsResponse.data.suggestion.flat();
          setValues((prevValues: any) => {
            // const existingKeywords = prevValues;
            // const uniqueNewKeywords = newKeywords.filter((keyword: any) => !existingKeywords.includes(keyword));
            // return [...existingKeywords, ...uniqueNewKeywords];
            return newKeywords;
          });
          setEntInfo((prevValues: AgentProps) => {
            const existingKeywords = prevValues.target_keywords;
            const uniqueNewKeywords = newKeywords.filter((keyword:any) => !existingKeywords.includes(keyword));
      
            return {
              ...prevValues,
              // concat the suggested_keywords to target_keywords to use and save the generated keywords
              target_keywords: [...existingKeywords, ...uniqueNewKeywords], // do not alter the user inputted initial keywords
              suggested_keywords: newKeywords,
            };
          });

          setIsLoading(false);
          setIsGenerated(true);
          setLoadingState(false);
        });
      }
    });

    

    setIsLoading(false);
    setIsGenerated(true);
    setLoadingState(false);

  };

  // const pushTools = (e:any) => {
  //   const toolName = e.target.value;
  //   setEntInfo((prevState:AgentProps) => ({
  //     ...prevState,
  //     tools: {
  //       ...prevState.tools,
  //       [toolName]: e.target.checked
  //     }
  //   }));
  // };

  const handleKeywordsChange = (newKeywords: any) => {
    const removedKeywords = values.filter((keyword: string) => !newKeywords.includes(keyword));
  
    setEntInfo((prevValues: AgentProps) => ({
      ...prevValues,
      target_keywords: prevValues.target_keywords.filter((keyword: string) => !removedKeywords.includes(keyword))
    }));
  
    setValues(newKeywords);
  };

  const handleInputsChange = (e: any) => {
    const newKeywords = e.target.value;
    const removedKeywords = entInfo.target_keywords.filter((keyword: string) => !newKeywords.includes(keyword));
  
    setEntInfo((prevValues: AgentProps) => ({
      ...prevValues,
      target_keywords: newKeywords
    }));
  
    if (removedKeywords.length > 0 && values && values.length > 0) {
      setValues((prevValues: any) => prevValues.filter((keyword: string) => !removedKeywords.includes(keyword)).concat(newKeywords.filter((keyword: string) => !prevValues.includes(keyword))));
    }
  };

  const handleNegativeInputsChange = (e: any) => {
    const newKeywords = e.target.value;
    const removedKeywords = entInfo.negative_keywords.filter((keyword: string) => !newKeywords.includes(keyword));
  
    setEntInfo((prevValues: AgentProps) => ({
      ...prevValues,
      negative_keywords: newKeywords
    }));
  
    if (removedKeywords.length > 0 && values && values.length > 0) {
      setValues((prevValues: any) => prevValues.filter((keyword: string) => !removedKeywords.includes(keyword)).concat(newKeywords.filter((keyword: string) => !prevValues.includes(keyword))));
    }
  };

  const handlePitchInputs = (e:any)=>{
    console.log("handle::", e);

    // console.log('handlePitchInputs ::: ', e)
    setValues2(e.htmlValue);
    setEntInfo((prevValues: AgentProps) => ({
      ...prevValues,
      suggested_pitch:e.htmlValue,
    }));
  }

  const countriesTemplate = (option: any) => {
    return (
      <div className="flex items-center">
        <img
          alt={option.name}
          src={`https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png`}
          className={`mr-2 flag ${option.flag ? `flag-${option.flag.toLowerCase()}` : ''}`}
          style={{ width: '18px' }}
        />
        <div>{option.name}</div>
      </div>
    );
  };

  const countryTemplate = (option: any, props: any) => {
    if (option && option.name) {
      return (
        <div className="flex items-center">
          <img
            alt={option.name}
            src={`https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png`}
            className={`mr-2 flag ${option.flag ? `flag-${option.flag.toLowerCase()}` : ''}`}
            style={{ width: '18px' }}
          />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const showBlueprint = (e:any) => {
    if( typeof(e.value)=='undefined' ){
      setDefaultBlueprint(null);
      setEntInfo((prevValues:any) => ({
        ...prevValues,
        outreach_template: null
      }));    
      return;
    }
  
    const defaultTemp = allTemplates.find((k:any) => (k.id == e.value));

    console.log("defaultTemp::", defaultTemp);

    setDefaultBlueprint(defaultTemp.id);
    setEntInfo((prevValues:any) => ({
      ...prevValues,
      outreach_template: defaultTemp.blueprint
    }));
  }

  const initBlueprints = () => {
    if(allTemplates != null && allTemplates.length != 0){
      const templateTemp = allTemplates.map((k:any) => (
        {
          label: k.name,
          value: k.id,
          key: k.id
        }
      ));
      const defaultTemp = allTemplates.find((k:any) => (k.default == 1));

      if(defaultTemp != null){
        setDefaultBlueprint(defaultTemp.id);
        setEntInfo((prevValues:any) => ({
          ...prevValues,
          outreach_template: defaultTemp.blueprint
        }));
      }
  
      setBlueprintTemplates(templateTemp);
    }
  };

  useEffect(() => {
    document.getElementById('top-client')?.scrollIntoView({ behavior: 'smooth' });
    initBlueprints();
  }, []);
  
  const hidden_zhsvnp = (entInfo.has_keyword_search==="0") ? ' hidden ' : '';

  return (
    <div className="flex flex-col"  id="top-client">
      <div className="flex flex-col w-full bg-[#fff] p-[20px] border-r border-[#ddd]" id="form-section">

        <div className="flex flex-row justify-between w-full">
        <h2 className="text-[24px] font-[700] mb-[.5rem]">Who are your potential leads</h2>
          <div className="flex flex-row  items-center hover:cursor-pointer icon-container group" onClick={()=>{closeOnboarding()}}>
            <span className="text-[16px] text-red-600 font-[600]">Exit Onboarding </span>
            {IconMap('FaAnglesRight', 'ml-2 transition transform duration-300 ease group-hover:translate-x-[5px] text-red-600',undefined,16)} 
          </div>
        </div>
        <p className="text-[14px] mb-[2rem]">Please provide details about your potential leads. This information will help tailor your marketing and outreach efforts to effectively target the right audience. </p>
        
          {/*
          <label className="flex items-center text-[16px] font-[500] mb-2 flex gap-2">
            <span className="flex items-center">
              Import CSV Leads
              <span className="font-normal italic text-gray-400">&nbsp;(Optional)</span>
              {IconMap('FaQuestionCircle','client-targets-xv7nzh ms-1 items-center text-[#585858]',undefined,16)}
              <Tooltip target=".client-targets-xv7nzh" content={"Upload your leads in CSV format. Download a sample file for reference."}/>
            </span>  
           
          </label>
<p className="text-[14px] mb-[3px]"><span>To import a CSV file, please use the following format from the sample file.</span> <span className="text-blue-300 italic cursor-pointer hover:text-blue-400" onClick={downloadSampleFile}>[Download Sample File]</span></p>             
          

          <FileUpload ref={fileUploadUi} mode="basic" customUpload={true} uploadHandler={fileResetHandler} onSelect={fileSelectHandler} chooseLabel="Choose CSV File" name="file[]" url="" accept="text/csv" maxFileSize={104857600}  />       
          <p>&nbsp;</p>
          */}


          {/*
            (csvFile !== null) && 
            (<>
            <label className="flex items-center text-[16px] font-[500] mb-2 flex gap-2">
              <span className="flex items-center">
                Enable / Disable Keyword Search
                {IconMap('FaQuestionCircle','client-targets-593ahy ms-1 items-center text-[#585858]',undefined,16)}
                <Tooltip target=".client-targets-593ahy" content={"Choose whether to enable or disable keyword search functionality for imported leads. If enabled, the system will match your leads based on keyword criteria."}/>
              </span>  
            </label>
  <p className="text-[14px] mb-[3px]">An option to disable / enable the keyword search when the import CSV file is present.</p>    
  
            <Dropdown value={ entInfo.has_keyword_search}
              options={HasKeywordSearchOptions} 
              onChange={handleHasKeywordSearchChange}
              placeholder="Has Keyword Search"
            />  
            <p>&nbsp;</p>  
           
            </>)
          */}        
        
        
        <div className={`flex flex-col mb-2 rounded-[8px]`}>
          {/* <MultiSelect autoOptionFocus={false} focusOnHover={false} selectOnFocus={false} value={entInfo.target_countries} options={countryOptions} onChange={(e: MultiSelectChangeEvent) => setSelectedCountries(e.value)} optionLabel="name" 
            filter placeholder="Select Countries" itemTemplate={countryTemplate} panelFooterTemplate={panelFooterTemplate} className="w-full md:w-20rem" display="chip" /> */}
          <Dropdown value={ entInfo.target_countries[0]}
            options={countryOptions} 
            onChange={(e: any) => 
              setSelectedCountries([e.value])
            } 
            placeholder="Select Country"
            optionLabel="name"
            itemTemplate={countriesTemplate}
            valueTemplate={countryTemplate}
            filter
            className={hidden_zhsvnp}
          />
          
          { /*
            <p>&nbsp;</p>
            
            <label className="flex items-center text-[16px] font-[500] mb-2 flex gap-2">
              <span className="flex items-center">
                Target Contact Person Seniority Levels
                {IconMap('FaQuestionCircle','client-targets ms-1 items-center text-[#585858]',undefined,16)}
                <Tooltip target=".client-targets" content={"Enter The Target Contact Person Seniority Levels"}/>
              </span>          
            </label>          
            
            <MultiSelect
              value={entInfo.target_contact_person_seniority_levels}
              options={SeniorityOptions}
              onChange={handleSeniorityChange}
              placeholder="Select Target Contact Person Seniority"
            />        
          */ }
          
          <p className={hidden_zhsvnp}>&nbsp;</p>
          
          <label className="flex items-center text-[16px] font-[500] mb-2 flex gap-2">
            <span className="flex items-center">
              Target Contact Person Management Levels Persona
              {IconMap('FaQuestionCircle','client-targets-wcszifcopr ms-1 items-center text-[#585858]',undefined,16)}
              <Tooltip target=".client-targets-wcszifcopr" content={"Enter The Target Contact Person Management Levels Persona"}/>
            </span>          
            <span>
              <Button onClick={() => {
                setEntInfo((prevValues: AgentProps) => ({
                  ...prevValues,
                  target_contact_person_management_levels: [],
                }));
              }} className="bg-purple text-white rounded-full px-[10px] py-[1px]" icon="pi pi-times-circle" label="Clear" />
            </span>
          </label>
          <MultiSelect
            value={entInfo.target_contact_person_management_levels}
            options={ManagementLevelOptions}
            onChange={handleManagementLevelChange}
            placeholder="Select Target Contact Person Management Levels"
          />
          
          
          
          <p>&nbsp;</p>
          
          <label className="flex items-center text-[16px] font-[500] mb-2 flex gap-2">
            <span className="flex items-center">
              Target Contact Person Department Persona
              {IconMap('FaQuestionCircle','client-targets-yqko0avidu ms-1 items-center text-[#585858]',undefined,16)}
              <Tooltip target=".client-targets-yqko0avidu" content={"Enter The Target Contact Person Department Persona"}/>
            </span>
            <span>
              <Button onClick={() => {
                setEntInfo((prevValues: AgentProps) => ({
                  ...prevValues,
                  target_contact_person_departments: [],
                }));
              }} className="bg-purple text-white rounded-full px-[10px] py-[1px]" icon="pi pi-times-circle" label="Clear" />
            </span>            
          </label>
          <TreeSelect
            value={entInfo.target_contact_person_departments}
            options={DepartmentsOptions}
            onChange={handleDepartmentChange}
            selectionMode="checkbox"
            placeholder="Select Target Contact Person Department Persona"
            className="w-full md:w-20rem"
            metaKeySelection={false}
          />          
          <p>&nbsp;</p>
          



          <label className={"flex items-center text-[16px] font-[500] mb-2 flex gap-2"+hidden_zhsvnp}>
            <span className="flex items-center">
              Choose the scope of search: broad or narrow
              {IconMap('FaQuestionCircle','client-targets-gzhaiiqddu ms-1 items-center text-[#585858]',undefined,16)}
              <Tooltip target=".client-targets-gzhaiiqddu" content={"Enter The Scope or The Approach of Search"}/>
            </span>           
          </label>
          <Dropdown value={ entInfo.search_scope}
            options={SearchScopeOptions} 
            onChange={handleSearchScopeChange}
            placeholder="Select The Scope"
            className={hidden_zhsvnp}
          />       
          
          <p className={hidden_zhsvnp}>&nbsp;</p>

          
          
        </div>
        <Inputs className={hidden_zhsvnp} label={
          <>
            <span className="flex items-center">
              Please provide a list of keywords for your potential leads you are targeting 
              {IconMap('FaQuestionCircle','client-targets ms-1 items-center text-[#585858]',undefined,16)}
              <Tooltip target=".client-targets" content={"Enter keywords related to your target leads"}/>
            </span>
            <span>
              <Button onClick={() => {
                setEntInfo((prevValues: AgentProps) => ({
                  ...prevValues,
                  target_keywords: [],
                }));
              }} className="bg-purple text-white rounded-full px-[10px] py-[1px]" icon="pi pi-times-circle" label="Clear" />
            </span>
          </>} labelClass={'text-[16px] font-[500] mb-2 flex gap-2'} type={'chips'} value={entInfo.target_keywords} 
          onChange={handleInputsChange}
          pt={{
            container:{className:'overflow-auto'},
            token: { className: 'my-1'}}}
        />

        <Inputs className={hidden_zhsvnp} label={
          <>
            <span className="flex items-center">
              Please provide a list of keywords to exclude on your potential leads you are targeting 
              {IconMap('FaQuestionCircle','client-targets ms-1 items-center text-[#585858]',undefined,16)}
              <Tooltip target=".client-targets" content={"Enter keywords related to your target leads"}/>
            </span>
            <span>
              <Button onClick={() => {
                setEntInfo((prevValues: AgentProps) => ({
                  ...prevValues,
                  negative_keywords: [],
                }));
              }} className="bg-purple text-white rounded-full px-[10px] py-[1px]" icon="pi pi-times-circle" label="Clear" />
            </span>
          </>} labelClass={'text-[16px] font-[500] mb-2 flex gap-2'} type={'chips'} value={entInfo.negative_keywords} 
          onChange={handleNegativeInputsChange}
          pt={{
            container:{className:'overflow-auto'},
            token: { className: 'my-1'}}}
        />
        
        {
          ( !( _.isEmpty( entInfo.target_keywords ) ) ) && 
          ( <div>
            <div className="flex justify-end mb-[2rem]">
              {/* <Button tooltip={`${isLoadingConsultAiInputedKeywords? 'Please wait' :selectedCountries.length == 0 || !entInfo.offers?'':''}`} tooltipOptions={{ showOnDisabled: true }} disabled={isLoadingConsultAiInputedKeywords || isLoading || !entInfo.target_keywords} onClick={handleButtonClickGetConsultAiInputedKeywords} className={`flex items-center mr-2 md:mr-0 mb-2 md:mb-0 py-2 px-3 text-[16px] text-white font-bold leading-6 md:leading-7 rounded-md disabled:opacity-50 ${isLoadingConsultAiInputedKeywords? 'bg-[#8B88BC]':'bg-[#3C35A7]'}`}>
                Consult with AI to Improve Search Keywords {isLoadingConsultAiInputedKeywords? <><ProgressSpinner className="w-[25px] h-[25px] ms-1" strokeWidth="6" /></> : <></>}
              </Button> */}
            </div>
          </div> )
        }



        
        
        {/* <Inputs label={'What is your Company Name?'} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={undefined} placeholder={'Seditio.com'} onChange={undefined} className="md:col-span-2 mb-8" /> */}
         {/* <Inputs label={'What countries should we search in'} labelClass={'text-[16px] font-[500] mb-2'} type={'multiselect'} value={selectedCountries} options={countryOptions} onChange={handleMultiSelectChange} itemTemplate={countryTemplate} className={"rounded-[8px] md:col-span-2 mb-8"} display="chip" /> */}
         <Inputs label={<>What offer / CTA do you want to provide these potential customers? {IconMap('FaQuestionCircle','client-customers ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".client-customers" content={"Describe the products, services, or promotions you want to offer to potential customers"} /></>} labelClass={'text-[16px] font-[500] mb-2'} type={'textarea'} rows={5} value={entInfo.offers} onChange={(e:any) => InputHandler(setEntInfo, 'offers')(e)} className={"rounded-[8px] md:col-span-2 mb-8"} />
        {/* <div className="card flex flex-wrap justify-center gap-[6rem]">
          <Inputs label={''} labelClass={'text-[16px] font-[500] mb-2'} type={'checkbox'} checked={entInfo.tools.google_search} value="google_search" checkboxLabel={'Google Search'} onChange={pushTools} className="md:col-span-2 mb-8" />
          <Inputs label={''} labelClass={'text-[16px] font-[500] mb-2'} type={'checkbox'} checked={entInfo.tools.google_places} value="google_places" checkboxLabel={'Google Places'} onChange={pushTools} className="md:col-span-2 mb-8" />
          <Inputs label={''} labelClass={'text-[16px] font-[500] mb-2'} type={'checkbox'} checked={entInfo.tools.linkedin} value="linkedin" checkboxLabel={'LinkedIn'} onChange={pushTools} className="md:col-span-2 mb-8" />
        </div> */}
        <Inputs value={defaultBlueprint} label={"Select a blueprint:"} onChange={(e:any) => showBlueprint(e)} options={blueprintTemplates} type={"dropdownc"} />
        <Inputs label={<>Add a blueprint for your outreach template, this will help inspire in generating your first emails towards your clients. {IconMap('FaQuestionCircle','client-customers ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".client-customers" content={"Give an example or a blueprint on your first emails to your future clients."} /></>} labelClass={'text-[16px] font-[500] mb-2'} style={{ height: '320px' }} type={'editor'} rows={5} value={entInfo.outreach_template} onChange={(e:any) => InputHandler(setEntInfo, 'outreach_template')(e)} className={"rounded-[8px] md:col-span-2 mb-8"} />
        <div className="flex justify-end mb-[2rem]">
          <Button tooltip={`${isLoading? 'Please wait' :selectedCountries.length == 0 || !entInfo.offers?'':''}`} tooltipOptions={{ showOnDisabled: true }} disabled={isLoading || !entInfo.offers} onClick={(e:any)=>handleGenerating(e)} 
            className={`flex items-center mr-2 md:mr-0 mb-2 md:mb-0 py-2 px-3 text-[16px] text-white font-bold leading-6 md:leading-7 rounded-md disabled:opacity-50 ${isLoading? 'bg-[#8B88BC]':'bg-[#3C35A7]'}`}>
            {(hidden_zhsvnp===' hidden ')?'Generate Pitch':'Generate Keywords & Pitch'}  {isLoading? <><ProgressSpinner className="w-[25px] h-[25px] ms-1" strokeWidth="6" /></> : <></>}
          </Button>
        </div>
        
        
        {
          (!(isLoading || selectedCountries.length == 0 || !entInfo.offers || _.isEmpty( values ) )) && ( hidden_zhsvnp!==' hidden ' ) &&
          ( <div>
            <div className="flex justify-end mb-[2rem]">
              <Button tooltip={`${isLoadingUrl? 'Please wait' :selectedCountries.length == 0 || !entInfo.offers?'':''}`} tooltipOptions={{ showOnDisabled: true }} disabled={isLoadingUrl || isLoading || !entInfo.target_keywords || entInfo.search_scope == 'Narrow'} onClick={handleButtonClickGetUrls} className={`flex items-center mr-2 md:mr-0 mb-2 md:mb-0 py-2 px-3 text-[16px] text-white font-bold leading-6 md:leading-7 rounded-md disabled:opacity-50 ${isLoadingUrl? 'bg-[#8B88BC]':'bg-[#3C35A7]'}`}>
                Preview Tentative Leads' URL Search Results for 1 Credit {isLoadingUrl? <><ProgressSpinner className="w-[25px] h-[25px] ms-1" strokeWidth="6" /></> : <></>}
              </Button>
            </div>
          </div> )
        }
        
        
        <div className="flex flex-col gap-1" id="preview-section">
          {/* <h4 className="text-[16px] font-[500]">Please see below our suggested keywords</h4> */}
          <div className={"flex gap-2 items-center"+hidden_zhsvnp}>
            <h4 className="text-[16px] font-[500]">Feel free to update and adjust the keywords list</h4>
            <Button onClick={() => {
              setValues([]);
              setEntInfo((prevValues: AgentProps) => ({
                ...prevValues,
                suggested_keywords: [],
              }));
            }} className="bg-purple text-white rounded-full px-[10px] py-[1px]" icon="pi pi-times-circle" label="Clear" />
          </div>
          <div className={hidden_zhsvnp+`mb-[2rem] h-[350px] bg-white border-gray border rounded-lg ${isLoading ? 'flex flex-col justify-center items-center' : values.length >1? 'block' :'flex flex-col items-center justify-center '}`}>
            {isLoading ? (
              <div className="text-[16px] text-[#3490dc] flex  justify-center">
                Generating information <ProgressSpinner className="w-[25px] h-[25px] ms-1" strokeWidth="6" />
              </div>
            ) : (
              values?.length > 1 ? (
                <Chips value={values}
                  className="w-full h-full rounded-lg border-none"
                  // readOnly
                  removable={true}
                  pt={{container: {className: 'w-full flex items-start rounded-lg border-none overflow-auto', style:{alignContent:'flex-start'}},
                  input: { className: 'h-full', readOnly: true },
                  token: { className: 'my-1'}
                  }} 
                  // onChange={(e:any) => setValues(e.value)}
                  onChange={(e: any) => handleKeywordsChange(e.value)}
                />
                // <Chips className="w-full h-full" pt={{container: {className: 'w-full overflow-scroll border-none rounded-lg gap-[5px]'}}} readOnly value={values} />
              ) : (
                <p className="text-[16px] text-[#8D93A1]">Pending information</p>
              )
            )}
          </div>
          <h4 className="text-[16px] font-[500]">Please see below our suggested pitch and first time contact</h4>
          <div className={`bg-white border-gray border rounded-lg ${isLoading2 ? 'flex flex-col items-center justify-center ' : values2? 'block' :'flex flex-col items-center justify-center ' }`}>
            {isLoading2 ? (
              <div className="text-[16px] text-[#3490dc] flex justify-center">
                Generating information <ProgressSpinner className="w-[25px] h-[25px] ms-1" strokeWidth="6" />
              </div>
            ) : (
              values2 !== null && values2 !== undefined && values2 !== "" ? (
                <Inputs className="w-full h-full rounded-lg border-none" value={values2} label={undefined} onChange={handlePitchInputs} style={{ height: '320px' }} type={"editor"} />
                // <InputTextarea className="w-full h-full rounded-lg border-none" value={values2} onChange={handlePitchInputs}  />
              ) : (
                <p className="text-[16px] text-[#8D93A1]">Pending information</p>
              )
            )}
          </div>
          <p className="text-[16px] text-[#8D93A1]">Feel free to update and adjust and press <strong>Next</strong> to continue</p>
        </div>
      </div>
    </div>
  );
};

export default ClientsStep;

import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Inputs from "../../components/forms/Inputs";
import { Logo, LoginIMG,LoginPlayIMG } from "../../components/Icons/ImgPath";
import { SignupProperties } from "../../interface/datatypes";
import { InputHandler } from "../../services/EventHandlers";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useAxios } from "../../contexts/AxiosContext";
import { Button } from "primereact/button";
import { useToast } from "../../contexts/ToastContext";
import { Dialog } from "primereact/dialog";
import OnboardingGuide from "../../components/modals/helps/OnboardingGuide";
// import GoogleButton from "../../components/buttons/GoogleButton";

const SignUpV2: React.FC<any> = ({setLoadingState, signupDetails}) => {
  const axiosService = useAxios();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [videoGuideModal, setVideoGuideModal] = useState(false);
  const [signupInfo, setSignupInfo] = useState<SignupProperties>({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const handleRegistration = () => {
    if(signupDetails.variable.password != signupDetails.variable.password_confirmation){
      showToast({
        severity: 'error',
        summary: 'Invalid Registration!',
        detail: "Passwords do not match."
      });
      return null;
    }
    setLoadingState(true);
    sessionStorage.clear();
    Cookies.remove('bearer_token');
    navigate('/subscription-registration');
    // axiosService.api.post('/api/account/register', signupInfo)
    //   .then((response:any) => {
    //     setLoadingState(false);
    //     const bearerToken = "Bearer " + response.data.authorisation.token;
    //     Cookies.set('bearer_token', bearerToken);
    //     axiosService.setHeaders(bearerToken);
    //     // axiosService.api.defaults.headers.common['Authorization'] = bearerToken;
    //     navigate('/subscription');
    //   }).catch((error:any) => {
    //     setLoadingState(false);
    //     console.log("message::", error.response)
    //     showToast({
    //       severity: 'error',
    //       summary: 'Invalid Registration!',
    //       detail: error.response.data.message
    //     });
    //     console.error('Error fetching data:', error);
    //   });
  };
  const handlePhoneChange = (newValue: any) => {
    signupDetails.setter((prevValues:any) => ({
      ...prevValues,
      phone: newValue
    }));
  };

  const [googleLoading, setGoogleLoading] = useState(false);
  const handleOnSuccess = (codeResponse: any) => {
    showToast({
      severity: 'info',
      summary: 'Processing',
      detail: 'Please wait...'
    });
    setLoadingState(true);
    axiosService.api.post('/api/auth/google/signup', {code: codeResponse.code})
      .then((response:any) => {
        setLoadingState(false);
        if(response.data.status){
          const bearerToken = "Bearer " + response.data.authorisation.token;
          Cookies.set('bearer_token', bearerToken);
          axiosService.setHeaders(bearerToken);
          showToast({
            severity: 'success',
            summary: 'Success',
            detail: 'Sign up sucessful.'
          });
          navigate('/main');        
        } else {
          showToast({
            severity: 'error',
            summary: 'Sign Up Failed!',
            detail: response.data.message
          });
        }
        setGoogleLoading(false);
      }).catch((error:any) => {
        setLoadingState(false);
        showToast({
          severity: 'error',
          summary: 'Error!',
          detail: 'Encountered an error, please refresh the page!'
        });
        console.error('Error fetching data:', error);
        setGoogleLoading(false);
      });
  }

  useEffect(() => {
    setTimeout(() => {
      setLoadingState(false);
    }, 300);
  }, []);

  // This needs fixing, after signup token does not update unless refreshed
  // useEffect(() => {
  //   if(isSet && (Cookies.get('bearer_token') != undefined)){
  //     window.location.reload();
  //   }else if(!isSet && Cookies.get('bearer_token')){
  //     navigate('/onboarding');
  //   }
  // }, [isSet]);

  return (
    <div className="min-h-screen bg-white flex">
      {/* Left Side */}
      <div className="hidden md:flex flex-1 flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-[#F0EFFF]">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="flex flex-col items-center">
            {/* */}
            {/* <img className="h-12 w-auto mb-5" src={Logo} alt="SectorSift Logo"/>
            <img className="mx-auto mb-4 ml-[-12rem] mt-[-1rem] h-auto w-[700px] max-w-[700px]" src={LoginIMG} alt="Login Logo"/>
             */}
             <img className="h-12 w-auto mb-5" src={Logo} alt="SectorSift Logo"/>
            <button
              className="mx-auto mb-4 ml-[-12rem] mt-[-1rem] h-auto w-[700px] max-w-[700px] p-0 border-none bg-transparent hover:cursor-pointer"
              onClick={() => setVideoGuideModal(true)}
            >
              <img
                className="w-full h-auto"
                src={LoginPlayIMG}
                alt="Login Logo"
              />
            </button>
            <div className="mt-[-5rem] flex flex-col justify-center">
              <h2 className="text-3xl font-medium text-gray-900 text-center">
                Try our new B2B Lead Generation Platform now.
              </h2>
              <p className="mt-2 text-[20px] font-light text-center text-gray-600">
                AI-powered platform streamlining B2B lead generation through precision targeting and market analysis.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*Right Side  */}
      <div className="w-full flex justify-center items-center p-[3rem]">
        <div className="w-full max-w-lg">
          <div className="w-full">
            {/*  Sign up form*/}
            <div className="mb-4 flex flex-col items-center">
              <img className="block md:hidden h-12 w-auto mb-4" src={Logo} alt="SectorSift Logo"/>
              <h3 className="mb-5 text-[28px] leading-6 font-medium text-gray-900 leading-[40px] text-center">Sign up and grow your business with <span className='font-poppins'>SectorSift</span></h3>
              {/* <GoogleButton loading={googleLoading} setLoading={setGoogleLoading} label={'Signup with Google'} onSuccess={handleOnSuccess} className="w-full border-purple border bg-white text-purple font-bold rounded-lg border-purple" scope={'https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/userinfo.email'} /> */}
              {/* <Button label="Signup with Google" icon={(<img width={20} src={GoogleIcon}/>)}/> */}
            </div>
            {/* <div className="relative mb-5 text-center text-xs font-normal leading-5 text-gray-500">
              <h4>Or</h4>
            </div> */}
            <div className="mt-6">
              {/* Input fields and buttons */}
              <form className="mb-5">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">

                  <Inputs label={'First Name'} type={'text'} value={signupDetails.variable.firstname} onChange={InputHandler(signupDetails.setter, 'firstname')} required invalid withValidation />
                  <Inputs label={'Last Name'} type={'text'} value={signupDetails.variable.lastname} onChange={InputHandler(signupDetails.setter, 'lastname')} required invalid  withValidation />
                  <Inputs label={'Email'} type={'email'} value={signupDetails.variable.email} onChange={InputHandler(signupDetails.setter, 'email')}  required invalid withValidation />
                  <Inputs label={'Phone Number'} type={'phone'} value={signupDetails.variable.phone} onChange={(e:any)=>handlePhoneChange(e)} required invalid  withValidation />
                </div>
                {/* Password field */}
                  {/* <Inputs label={'Password'} type={'password'} value={signupDetails.variable.password} onChange={InputHandler(signupDetails.setter, 'password')} className="md:col-span-2" required invalid  withValidation />
                  <Inputs label={'Confirm Password'} type={'password'} value={signupDetails.variable.password_confirmation} onChange={InputHandler(signupDetails.setter, 'password_confirmation')} className="md:col-span-2" required invalid  withValidation/> */}
              </form>
              <div className="">
                <Button label="Sign me up" onClick={handleRegistration} className="w-full bg-[#397BFF] rounded-lg border-[#397BFF]" pt={{ label: { className: 'font-[600]' } }}/>
                <p className="text-center text-[12px] leading-7 font-normal mb-4">
                  By clicking Sign me up you agree to SectorSift's <a href="/terms-and-conditions" target="_blank" className="underline hover:cursor-pointer">Terms and Conditions</a> and <a href="/privacy-policy" target="_blank" className="underline hover:cursor-pointer">Privacy Policy</a>.
                </p>

                <p className="text-center text-[16px] leading-7 font-normal mb-5">
                  Already have an account? <Link to="/" className="underline cursor-pointer">Login!</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog className="lg:w-50% w-[95%] text-center" header="" closable={true} draggable={false} resizable={false} visible={videoGuideModal} onHide={() => setVideoGuideModal(false)}>
        <section className="p-4 border-b-[1px] border-gray shadow-sm w-full bg-white z-[400]">
          <h1 className="text-3xl font-bold mb-4">Video Guide</h1>
          <p className="text-sm text-gray-500 mb-6">
            <strong>Video guide to help you get started</strong>
          </p>
        </section>
        <OnboardingGuide/>
      </Dialog>
    </div>
  );
}

export default SignUpV2;

import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { InputTextarea } from 'primereact/inputtextarea';
import { Chips } from 'primereact/chips';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from "primereact/checkbox";
import { Editor } from 'primereact/editor';
import { Calendar } from 'primereact/calendar'; 
import { Dropdown } from 'primereact/dropdown';      
import PhoneInput from 'react-phone-number-input'
import { Slider } from 'primereact/slider';
import {FormValidations,ValidationMessage} from './FormValidations';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import CustomEditor from './PrimereactEditor';

type InputProps = {
  options?: any[];
  style?: any;
  value: any;
  onChange?: any;
  onKeyDown?: any;
  label: any;
  id?: any,
  type: string,
  icon?: string,
  placeholder?: string,
  required?: boolean,
  readOnly?:boolean,
  tooltip?: any,
  name?: any,
  disabled?: boolean,
  target?: string
  defaultValue?: any,
  format?: any,
  mode?:any,
  picker?: any,
  disabledDate?:any,
  dataId?: any,
  withValidation?: boolean,
  disableAutocomplete?: boolean,
  inputType?: any,
  suffix?: any,
  labelClass?:any,
  grouped?:boolean,
  prefix?: any,
  props?:any,
  loading?:boolean,
  showTime?:boolean,
  maxCount?:number,
  className?:any,
  rows?:number,
  cols?:number,
  itemTemplate?:any,
  panelFooterTemplate?:any,
  display?:any,
  checkboxLabel?:any,
  checked?:any,
  key?:any,
  keyfilter?: any,
  inputOnChange?:any,
  invalid?:any,
  pt?:any,
  optionLabel?: any,
  centerText?: boolean,
  missingField?: any
};

const Inputs: React.FC<InputProps> = ({
  missingField, target, style, options, value, onChange, onKeyDown,
  label, id, type, icon, placeholder, invalid, required, tooltip,
  name, disabled=false, readOnly, defaultValue, format, mode,
  picker, disabledDate, dataId, withValidation, disableAutocomplete,
  inputType, props, loading, showTime, maxCount, className, rows, cols,
  suffix, prefix,grouped,labelClass,itemTemplate,panelFooterTemplate,
  display,checked,checkboxLabel,key,keyfilter, inputOnChange,pt, optionLabel = null, centerText=false
}) => {

  const [touched, setTouched] = useState(false);

  const handleFocus = () => {
    setTouched(true);
  };
 
  const inputChosen = (type:any) =>{

    switch (type) {
      case 'number':
        return (
          <div className={`flex flex-col mb-2 ${className}`}>
            <label className={`flex items-center ${labelClass ? labelClass : 'text-gray-700 text-sm font-bold mb-2'}`}>{label}{required? <span>*</span>:null}</label>
            {grouped && (
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon"> {prefix} </span>
                <InputNumber 
                  required={required}
                  value={value} 
                  onChange={onChange} 
                  style={{width: "100%"}}
                  placeholder={placeholder}
                  onFocus={handleFocus}
                  className={`p-inputtext-sm block`}  
                  disabled={disabled}  
                  invalid={invalid && required && touched && FormValidations.isEmpty(value)}
                />
              </div>
            )}
            {!grouped && <>
              <InputNumber 
                required={required}
                value={value} 
                onChange={onChange} 
                style={{width: "100%"}}
                placeholder={placeholder}
                className={`block`}  
                onFocus={handleFocus}
                disabled={disabled} 
                invalid={invalid && required && touched && (FormValidations.isEmpty(value))}
              />
            </>}
            {withValidation && touched && <ValidationMessage value={value} type={type} required={required} />}
          </div>
        );
        case 'calendar':
          return (
            <div className={`flex flex-col mb-2 ${className}`}>
              <label className={`flex items-center ${labelClass ? labelClass : 'text-gray-700 text-sm font-bold mb-2'}`}>{label}{required? <span>*</span>:null}</label>
              {grouped && (
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon"> {prefix} </span>
                  <Calendar 
                    required={required}
                    value={value} 
                    onChange={onChange} 
                    // style={{width: "100%"}}
                    placeholder={placeholder}
                    onFocus={handleFocus}
                    className={`p-inputtext-sm block`}  
                    disabled={disabled}  
                    invalid={invalid && required && touched && FormValidations.isEmpty(value)}
                  />
                </div>
              )}
              {!grouped && <>
                <Calendar 
                  required={required}
                  value={value} 
                  onChange={onChange} 
                  // style={{width: "100%"}}
                  placeholder={placeholder}
                  // className={`block`}  
                  onFocus={handleFocus}
                  disabled={disabled} 
                  invalid={invalid && required && touched && (FormValidations.isEmpty(value))}
                />
              </>}
              {withValidation && touched && <ValidationMessage value={value} type={type} required={required} />}
            </div>
          );
      case 'text':
        return (
          <div className={`flex flex-col mb-2 ${className}`}>
            <label className={`flex items-center ${missingField ? `text-red-500` : ``} ${labelClass ? labelClass : 'text-gray-700 text-sm font-bold mb-2'}`}>{label}{required? <span>*</span>:null}</label>
            {grouped && (
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon"> {prefix} </span>
                <InputText 
                  required={required}
                  value={value} 
                  onChange={onChange} 
                  style={{width: "100%", ...(centerText && { textAlign: "center" })}}
                  placeholder={placeholder}
                  onFocus={handleFocus}
                  className={`p-inputtext-sm block`}  
                  disabled={disabled}  
                  invalid={invalid && required && touched && FormValidations.isEmpty(value)}
                />
              </div>
            )}
            {!grouped && <>
              <InputText 
                required={required}
                value={value} 
                onChange={onChange} 
                style={{width: "100%", ...(centerText && { textAlign: "center" })}}
                placeholder={placeholder}
                className={`block`}  
                onFocus={handleFocus}
                disabled={disabled} 
                invalid={invalid && required && touched && (FormValidations.isEmpty(value))}
              />
            </>}
            {withValidation && touched && <ValidationMessage value={value} type={type} required={required} />}
          </div>
        );
      case 'textarea':
        return (
          <div className={`flex flex-col mb-2 ${className}`}>
            <label className={`flex items-center ${labelClass ? labelClass : 'text-gray-700 text-sm font-bold mb-2'}`}>{label}{required? <span>*</span>:null}</label>
            {grouped && (
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon"> {prefix} </span>
                <InputTextarea 
                  value={value} 
                  rows={rows} 
                  cols={cols} 
                  placeholder={placeholder}
                  onChange={onChange} 
                  style={{width: "100%"}}
                  disabled={disabled}
                  readOnly={readOnly}
                  className={`p-inputtext-sm block `}
                  invalid={invalid && required && (FormValidations.isEmpty(value))} />
              </div>
            )}
            {!grouped && 
              <InputTextarea 
                value={value} 
                rows={rows} 
                cols={cols} 
                placeholder={placeholder}
                onChange={onChange} 
                disabled={disabled}
                readOnly={readOnly}
                style={{width: "100%"}}
                className={`p-inputtext-sm block `}
                invalid={invalid && required && (FormValidations.isEmpty(value))} />
                }
          </div>
        );
      case 'editor':
        return(
          <div className={`flex flex-col mb-2 ${className}`}>
            <label className={`flex items-center ${labelClass ? labelClass : 'text-gray-700 text-sm font-bold mb-2'}`}>{label}</label>
            {grouped && (
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon"> {prefix} </span>
                <Editor value={value} 
                  name={name} 
                  onTextChange={onChange} 
                  style={style? style : { height: '120px' }} />
              </div>
            )}
            {!grouped && 
              <Editor value={value} 
              name={name} 
              onTextChange={onChange} 
              style={style? style : { height: '120px' }} />}
          </div>
        );
      case 'new-editor':
        return(
          <div className={`flex flex-col mb-2 ${className}`}>
            <label className={`flex items-center ${labelClass ? labelClass : 'text-gray-700 text-sm font-bold mb-2'}`}>{label}</label>
            {grouped && (
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon"> {prefix} </span>
                <CustomEditor value={value} 
                  name={name} 
                  onChange={onChange} 
                  style={style? style : { height: '120px' }} />
              </div>
            )}
            {!grouped && 
              <CustomEditor value={value} 
              name={name} 
              onChange={onChange} 
              style={style? style : { height: '120px' }} />}
          </div>
        );
      case 'email':
        return (
          <div className={`flex flex-col mb-2 ${className}`}>
            <label className={`flex items-center ${labelClass ? labelClass : 'text-gray-700 text-sm font-bold mb-2'}`}>{label}{required? <span>*</span>:null}</label>
            {grouped && (
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon"> {prefix} </span>
                <InputText 
                  required={required}
                  type='email'
                  value={value} 
                  onChange={onChange} 
                  style={{width: "100%"}}
                  placeholder={placeholder}
                  className={`p-inputtext-sm block`}  
                  disabled={disabled}  
                  onFocus={handleFocus}
                  invalid={invalid && touched && ((required && FormValidations.isEmpty(value)) || (!FormValidations.isValidEmail(value)))}
                />
              </div>
            )}
            {!grouped && <>
              <InputText 
                required={required}
                value={value} 
                onChange={onChange} 
                style={{width: "100%"}}
                placeholder={placeholder}
                className={`p-inputtext-sm block`}  
                disabled={disabled} 
                onFocus={handleFocus}
                invalid={invalid && touched && ((required && FormValidations.isEmpty(value)) || (!FormValidations.isValidEmail(value)))}
              />
            </>}
            {withValidation && touched && <ValidationMessage value={value} type={type} required={required} />}
          </div>
        );
      case 'password':
        return (
          <div className={`flex flex-col mb-2 ${className}`}>
            <label className={`flex items-center ${labelClass ? labelClass : 'text-gray-700 text-sm font-bold mb-2'}`}>{label}{required? <span>*</span>:null}</label>
            {grouped && (
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon"> {prefix} </span>
                <Password
                  required={required}
                  value={value}
                  onChange={onChange}
                  toggleMask={true}
                  feedback={false} 
                  placeholder={placeholder}
                  // inputStyle={{width: "100%"}} 
                  // style={{width: "100%"}}
                  // inputClassName={`p-inputtext-sm block w-full ${invalid && touched && (required && FormValidations.isEmpty(value)) ? 'p-invalid' : ''}`}
                  disabled={disabled}
                  onFocus={handleFocus}
                  onKeyDown={onKeyDown}
                  className={'w-full'}
                  // pt={{input:{className:'test'}}}
                  // pt={{iconField:{style:{width:'100%'}}}}
                />
              </div>
            )}
            {!grouped && 
              <Password
                required={required}
                value={value}
                onChange={onChange}
                toggleMask={true}
                feedback={false} 
                placeholder={placeholder}
                inputStyle={{width: "100%"}} 
                style={{width: "100%"}}
                onFocus={handleFocus}
                inputClassName={`p-inputtext-sm block w-full ${invalid && touched && (required && FormValidations.isEmpty(value)) ? 'p-invalid' : ''}`}
                disabled={disabled}
                onKeyDown={onKeyDown}
              />}
            {withValidation && touched && <ValidationMessage value={value} type={type} required={required} />}
          </div>
          
        );
      case 'phone':
        return(
          <div className={`flex flex-col mb-2 ${className}`}>
            <label className={`flex items-center ${labelClass ? labelClass : 'text-gray-700 text-sm font-bold mb-2'}`}>{label}{required? <span>*</span>:null}</label>
            <PhoneInput
              required={required}
              international
              placeholder="Enter phone number"
              value={value}
              onChange={onChange} 
              rules={{ required: true }} 
              disabled={disabled}
              // className={`${invalid && touched ? '':'invalid'}`}
              onFocus={handleFocus}
              className={`${touched ? ((invalid && value) || !required) ? '' : 'invalid' : ''}`}
              />
            {withValidation && touched && <ValidationMessage value={value} type={type} required={required} />}
          </div>
        );
      case 'chips':
        return (
          <div className={`flex flex-col mb-2 ${className}`}>
            <label className={`flex items-center ${labelClass ? labelClass : 'text-gray-700 text-sm font-bold mb-2'}`}>{label}{required? <span>*</span>:null}</label>
            {grouped && (
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon"> {prefix} </span>
                <Chips value={value}
                  style={{width: "100%"}}
                  className={`p-inputtext-sm block w-full `}
                  onChange={onChange}
                  pt={pt}
                  keyfilter={keyfilter} separator=',' allowDuplicate={false} addOnBlur={true}
                />
              </div>
            )}
            {!grouped && 
              <Chips value={value}
                style={{width: "100%"}}
                className={`p-inputtext-sm block w-full `}
                onChange={onChange}
                keyfilter={keyfilter} separator=',' allowDuplicate={false} addOnBlur={true}
                pt={pt}
              />
            }
          </div>
        );
      case'multiselect':
      return (
        <div className={`flex flex-col mb-2 ${className}`}>
          <label className={`flex items-center ${labelClass ? labelClass : 'text-gray-700 text-sm font-bold mb-2'}`}>{label}{required? <span>*</span>:null}</label>
          {grouped && (
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon"> {prefix} </span>
              
              <MultiSelect 
                value={value} 
                options={options} 
                onChange={onChange}
                placeholder={placeholder} 
                itemTemplate={itemTemplate} 
                panelFooterTemplate={panelFooterTemplate} 
                style={{width: "100%", display:'flex'}}
                className="flex p-inputtext-sm block w-full" 
                display={display}
                key={key}
              />
            </div>
          )}
          {!grouped && 
            <MultiSelect 
              value={value} 
              options={options} 
              onChange={onChange}
              placeholder={placeholder} 
              itemTemplate={itemTemplate} 
              panelFooterTemplate={panelFooterTemplate} 
              style={{width: "100%", display:'flex'}}
              className="flex p-inputtext-sm block w-full" 
              display={display}
              key={key}
            />}
        </div>
      );
      case'checkbox':
      return (
        <div className={`flex flex-col mb-2 ${className}`}>
          <label className={`flex items-center ${labelClass ? labelClass : 'text-gray-700 text-sm font-bold mb-2'}`}>{label}{required? <span>*</span>:null}</label>
          {grouped && (
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon"> {prefix} </span>
              <div className="flex align-items-center">
                <Checkbox inputId={id} name={name} value={value} onChange={onChange} checked={checked} />
                <label htmlFor={id} className="ml-2">{checkboxLabel}</label>
              </div>
            </div>
          )}
          {!grouped && 
          
            <div className="flex align-items-center">
              <Checkbox inputId={id} name={name} value={value} onChange={onChange} checked={checked} />
              <label htmlFor={id} className="ml-2">{checkboxLabel}</label>
            </div>}
        </div>
      );

      case 'date-range': 
        return (
          <div className={`flex flex-col mb-2 ${className}`}>
            <label className={`flex items-center ${labelClass ? labelClass : 'text-gray-700 text-sm font-bold mb-2'}`}>{label}{required? <span>*</span>:null}</label>
            {grouped && (
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon"> {prefix} </span>
                <Calendar 
                  value={value} 
                  onChange={onChange} 
                  selectionMode="range" 
                  readOnlyInput 
                  hideOnRangeSelection 
                />
              </div>
            )}
            {!grouped && 
              <Calendar 
              value={value} 
              onChange={onChange} 
              selectionMode="range" 
              readOnlyInput 
              hideOnRangeSelection 
            />}
          </div>
        );

      case 'dropdown':
        return (
          <div className={`flex flex-col mb-2 ${className}`}>
            <label className={`flex items-center ${labelClass ? labelClass : 'text-gray-700 text-sm font-bold mb-2'}`}>{label}{required? <span>*</span>:null}</label>
            {
              optionLabel === null ? 
                grouped ? (
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon"> {prefix} </span>
                    <Dropdown 
                      value={value} 
                      onChange={onChange} 
                      options={options} 
                      placeholder={placeholder}
                    />
                  </div>
                ) : (
                  <Dropdown 
                    value={value} 
                    onChange={onChange} 
                    options={options} 
                    placeholder={placeholder}
                  />
                )
              : 
                grouped ? (
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon"> {prefix} </span>
                    <Dropdown 
                      value={value} 
                      onChange={onChange} 
                      options={options} 
                      optionLabel={optionLabel}
                      placeholder={placeholder}

                    />
                  </div>
                ) : (
                  <Dropdown 
                    value={value} 
                    onChange={onChange} 
                    options={options} 
                    optionLabel={optionLabel}
                    placeholder={placeholder}
                  />
                )
            }
          </div>
        );
        
      case 'dropdownc':
        return (
          <div className={`flex flex-col mb-2 ${className}`}>
            <label className={`flex items-center ${labelClass ? labelClass : 'text-gray-700 text-sm font-bold mb-2'}`}>{label}{required? <span>*</span>:null}</label>
            {
              optionLabel === null ? 
                grouped ? (
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon"> {prefix} </span>
                    <Dropdown 
                      value={value} 
                      onChange={onChange} 
                      options={options} 
                      placeholder={placeholder}
                      showClear
                    />
                  </div>
                ) : (
                  <Dropdown 
                    value={value} 
                    onChange={onChange} 
                    options={options} 
                    placeholder={placeholder}
                    showClear
                  />
                )
              : 
                grouped ? (
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon"> {prefix} </span>
                    <Dropdown 
                      value={value} 
                      onChange={onChange} 
                      options={options} 
                      optionLabel={optionLabel}
                      placeholder={placeholder}
                      showClear

                    />
                  </div>
                ) : (
                  <Dropdown 
                    value={value} 
                    onChange={onChange} 
                    options={options} 
                    optionLabel={optionLabel}
                    placeholder={placeholder}
                    showClear
                  />
                )
            }
          </div>
        );        
        
      
      case 'slider':
        return (
          <div className={`flex flex-col mb-2 ${className}`}>
            <label className={`flex items-center ${labelClass ? labelClass : 'text-gray-700 text-sm font-bold mb-2'}`}>{label}{required? <span>*</span>:null}</label>
            {grouped && (
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon"> {prefix} </span>
                <InputText 
                  value={value} 
                  onChange={inputOnChange} 
                />
                <Slider value={value} onChange={onChange} max={999} range />
              </div>
            )}
            {!grouped && 
              <>
                <InputText 
                  value={value} 
                  onChange={inputOnChange} 
                />
                <Slider value={value} onChange={onChange} max={999} range />
              </>}
          </div>
        );

      case 'radio': 
      return (
        <div className={`flex flex-row mb-2 ${className}`}>
          {grouped && (
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon"> {prefix} </span>
              <RadioButton 
                inputId={dataId}
                name={dataId} 
                value={label} 
                onChange={onChange} 
                checked={label === value} 
              />
            </div>
          )}
          {!grouped && 
            <>
              <RadioButton 
                inputId={dataId}
                name={dataId} 
                value={label} 
                onChange={onChange} 
                checked={label === value} 
              />
            </>}
            <label className={`flex items-center ${labelClass ? labelClass : 'text-gray-700 text-sm font-bold ml-2 mb-2'}`}>{label}{required? <span>*</span>:null}</label>
          </div>
        );
      // Implement other cases as needed
      default:
        return <div>No input type selected</div>;
    }
  }

  return (
    inputChosen(type)
    // <div className={`flex flex-col mb-2 ${className}`}>
    //   <label className={`flex items-center ${labelClass ? labelClass : 'text-gray-700 text-sm font-bold mb-2'}`}>{label}</label>
    //   {grouped && (
    //     <div className="p-inputgroup">
    //       <span className="p-inputgroup-addon"> {prefix} </span>
    //       {inputChosen(type)}
    //     </div>
    //   )}
    //   {!grouped && inputChosen(type)}
    // </div>
  );
};



export default Inputs;

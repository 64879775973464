import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Inputs from "../../components/forms/Inputs";
import { Logo, LoginPlayIMG } from "../../components/Icons/ImgPath";
import { LoginTypes } from "../../interface/datatypes";
import { InputHandler } from "../../services/EventHandlers";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useAxios } from "../../contexts/AxiosContext";
import { Button } from "primereact/button";
import { useToast } from "../../contexts/ToastContext";
import { Dialog } from "primereact/dialog";
import OnboardingGuide from "../../components/modals/helps/OnboardingGuide";
// import GoogleButton from "../../components/buttons/GoogleButton";

const LogIn: React.FC<any> = ({setLoadingState}) => {
  const axiosService = useAxios();
  const { showToast } = useToast();
  const [loginMessage, setLoginMessage] = useState<any>("");

  const [videoGuideModal, setVideoGuideModal] = useState(false);
  const [loginCredentials, setLoginCredentials]:any = useState<LoginTypes>({
    email: "",
    password: ""
  });
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoadingState(false);
    }, 300);
  }, []);

  useEffect(() => {
    if(axiosService && Cookies.get('bearer_token')) {
      axiosService.api.get('/api/user/current').then((currentUserData:any) => {
        if(currentUserData != null && currentUserData != undefined){
          setLoggedIn(true);
        }
      }).catch((error:any) => {
        console.error('Request failed:', error.message);
      });
    }else{
      console.log("empty");
    }
  }, []);

  const handleKeyPress = (event:any) => {
    if (event.key === 'Enter') {
      attemptLogin();
    }
  };

  const attemptLogin = () => {

    showToast({
      severity: 'info',
      summary: 'Processing',
      detail: 'Please wait...'
    });
    
    setLoadingState(true);

    axiosService.api.post('/api/account/login', loginCredentials)
      .then((response:any) => {
        setLoadingState(false);
        const bearerToken = "Bearer " + response.data.authorisation.token;
        axiosService.api.defaults.headers.common['Authorization'] = bearerToken;
        Cookies.set('bearer_token', bearerToken);
        setLoggedIn(true);
        setTimeout(() => {
          showToast({ severity: 'success', summary: 'Success!', detail: 'Successfully logged in.', });
        },500);
      })
      .catch((error:any) => {
        setLoadingState(false);
        showToast({
          severity: 'error',
          summary: 'Unauthenticated!',
          detail: error.response.data.message,
        });
        setLoginMessage("Email or Password must be incorrect. Click on Forgot Password to reset your password.");
        console.error('Error fetching data:', error);
      });
  };

  const [googleLoading, setGoogleLoading] = useState(false);
  const handleOnSuccess = (codeResponse: any) => {
    setLoadingState(true);
    axiosService.api.post('/api/auth/google/login', {code: codeResponse.code})
      .then((response:any) => {
        if(response.data.status){
          const bearerToken = "Bearer " + response.data.authorisation.token;
          axiosService.api.defaults.headers.common['Authorization'] = bearerToken;
          Cookies.set('bearer_token', bearerToken);
          setLoggedIn(true);
        } else {
          showToast({
            severity: 'error',
            summary: 'Login Failed!',
            detail: response.data.message
          });
        }
        
        setGoogleLoading(false);
        setLoadingState(false);
      }).catch((error:any) => {
        console.error('Error fetching data:', error);
        setGoogleLoading(false);
        setLoadingState(false);
      });
  }

  if(loggedIn){
    return <Navigate to="/main?page=dashboard" />;
  }

  return (
    <div className="min-h-screen bg-white flex">
      {/* Left Side */}
      <div className="hidden md:flex flex-1 flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-[#F0EFFF]">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="flex flex-col items-center">
            {/* */}
            <img className="h-12 w-auto mb-5" src={Logo} alt="SectorSift Logo"/>
            <button
              className="mx-auto mb-4 ml-[-12rem] mt-[-1rem] h-auto w-[700px] max-w-[700px] p-0 border-none bg-transparent hover:cursor-pointer"
              onClick={() => setVideoGuideModal(true)}
            >
              <img
                className="w-full h-auto"
                src={LoginPlayIMG}
                alt="Login Logo"
              />
            </button>
            <div className="mt-[-5rem] flex flex-col justify-center">
              <h2 onClick={() => setVideoGuideModal(true)}  className="login-intro cursor-pointer text-3xl font-medium text-gray-900 text-center">
                Try our new B2B Lead Generation Platform now.
              </h2>
              <p className="mt-2 text-[20px] font-light text-center text-gray-600">
                AI-powered platform streamlining B2B lead generation through precision targeting and market analysis.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*Right Side  */}
      <div className="w-full flex justify-center items-center p-[3rem]">
        <div className="w-full max-w-lg">
          <div className="w-full">
            {/*  Login up form*/}
            <div className="mb-4 flex flex-col items-center">
              <img className="block md:hidden h-12 w-auto mb-4" src={Logo} alt="SectorSift Logo"/>
              <h3 className="mb-5 text-[28px] leading-6 font-medium text-gray-900 leading-[40px] text-center">Login and grow your business with <span className='font-poppins'>SectorSift</span></h3>
              {/* <GoogleButton loading={googleLoading} setLoading={setGoogleLoading} label={'Login with Google'} onSuccess={handleOnSuccess} className="w-full border-purple border bg-white text-purple font-bold rounded-lg border-purple" scope={'https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/userinfo.email'} /> */}
              {/* <Button label="Login with Google" icon={(<img width={20} src={GoogleIcon}/>)} className="w-full border-purple border bg-white text-purple font-bold rounded-lg border-purple"/> */}
            </div>
            {/* <div className="relative mb-5 text-center text-xs font-normal leading-5 text-gray-500">
              <h4>Or</h4>
            </div> */}
            <div className="mt-6">
              {/* Input fields and buttons */}
              <form className="mb-5">
                <Inputs label={'Email'} type={'text'} value={loginCredentials.email} onChange={InputHandler(setLoginCredentials, 'email')} />
                <Inputs label={'Password'} type={'password'} value={loginCredentials.password} onChange={InputHandler(setLoginCredentials, 'password')} onKeyDown={handleKeyPress} className="md:col-span-2" />
              </form>
              <div className="text-center text-[12px] text-red-500 mb-3">
                {loginMessage}
              </div>
              <div className="">
                <Button label="Login to Account" onClick={attemptLogin} 
                  className="w-full bg-[#397BFF] rounded-lg border-[#397BFF] font-regular"
                  pt={{ label: { className: 'font-[600]' } }}/>
                <p className="text-center text-[16px] leading-7 font-normal mb-5 mt-5">
                  <Link to="/forgot-password" className="underline cursor-pointer">Forgot password</Link>
                </p>
                {/* <p className="text-center text-[12px] leading-7 font-normal mb-4">
                  By clicking Sign me up you agree to SectorSift's <a href="/docs/terms_and_conditions.html" target="_blank" className="underline">Terms and Conditions</a> and <a href="/docs/privacy_policy.html" target="_blank"className="underline">Privacy Policy</a>.
                </p> */}

                <p className="text-center text-[12px] leading-7 font-normal mb-4">
                  By clicking Sign me up you agree to SectorSift's <a href="/terms-and-conditions" target="_blank" className="underline hover:cursor-pointer">Terms and Conditions</a> and <a href="/privacy-policy" target="_blank" className="underline hover:cursor-pointer">Privacy Policy</a>.
                </p>

                <p className="text-center text-[16px] leading-7 font-normal mb-5">
                  Don't have an account yet? <Link to="/signup" className="underline cursor-pointer">Sign up!</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <OnboardingGuide/> */}
      <Dialog className="lg:w-50% w-[95%] text-center" header="" closable={true} draggable={false} resizable={false} visible={videoGuideModal} onHide={() => setVideoGuideModal(false)}>
        <section className="p-4 border-b-[1px] border-gray shadow-sm w-full bg-white z-[400]">
          <h1 className="text-3xl font-bold mb-4">Video Guide</h1>
          <p className="text-sm text-gray-500 mb-6">
            <strong>Video guide to help you get started</strong>
          </p>
        </section>
        <OnboardingGuide/>
      </Dialog>
    </div>
    
  );
};

export default LogIn;
